body {
    margin: 0;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
    height: 100%;
}

html {
    /* Enable smooth scrolling on the whole document */
    scroll-behavior: smooth;
}

/* <------------------------------------------------------------------ home page  -------------------------------------------------> */
.lazy-homepage-background {
    background-color: rgb(62, 91, 109);
}

/* lazy load homepage background */
.lazy-homepage-background.visible {
    background-image: url("/images/header-banner.jpg"); /* The final image */
}

.bg-cover {
    width: 100%;
    height: 450px;
    background: url("/images/header-banner.jpg") no-repeat center;
    background-size: cover;
    border-radius: 50%;
}

#intro-red-line {
    left: 0;
    padding-top: 40px;
    /* position: absolute; */
    top: 27px;
    width: 25px;
}

#intro-red-line hr {
    background-color: red;
    border: 2px solid red;
    border-radius: 7px 7px 7px 7px;
    /* height: 2px; */
}

.default-button {
    /* width:  */
    height: 45px;
    width: 200px;
}
.spaced-list {
    padding-left: 15px;
}
.spaced-list li {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    div .man-phone {
        display: none !important;
        background-color: aqua;
    }
}

@media (min-width: 576px) {
    .need-more-dark {
        border-radius: 15px;
    }
}

.inuua-is-more-than-a-house-plan-image {
    height: 450px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

@media (max-width: 576px) {
    .inuua-is-more-than-a-house-plan-image {
        height: 250px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}

.rate {
    text-decoration: underline;
    text-decoration-color: red;
    text-underline-offset: 3px;
}

/* <------------------------------------------------------------------ end home page  -------------------------------------------------> */

/* <------------------------------------------------------------------ faq -------------------------------------------------> */
.dropdown-div {
    overflow: hidden;
    height: 0;
    padding: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.dropdown-div-open {
    height: auto;
    padding: 20px 0px 5px 0px;
}

/* <------------------------------------------------------------------ end faq  -------------------------------------------------> */

/* <------------------------------------------------------------------ dashboard  -------------------------------------------------> */
.sidebar {
    /* position: fixed;
    padding: 48px 0 0; */
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    min-height: 100vh !important;
    z-index: 100;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

/* <------------------------------------------------------------------ end dashboard  -------------------------------------------------> */
